<template>
  <div class="home">
    <el-card class="box-card">
      <el-descriptions :title="$t('detail.overview')" border>
        <el-descriptions-item :label="$t('index.device')">{{ detailInfo.imei }}</el-descriptions-item>
        <el-descriptions-item :label="$t('index.device_type')">{{ detailInfo.type_title }}</el-descriptions-item>
        <el-descriptions-item :label="$t('detail.admin')">{{ detailInfo.username }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.creation_time')">{{ detailInfo.create_at }}</el-descriptions-item>
        <el-descriptions-item :label="$t('index.battery_level')">{{ detailInfo.volt }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.vesting')">{{ detailInfo.client_name }}</el-descriptions-item>
        <el-descriptions-item :label="$t('detail.software')">{{ detailInfo.software_version }}</el-descriptions-item>
        <el-descriptions-item :label="$t('detail.hardware')">{{ detailInfo.hardware_version }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.last_comm_time')">{{
            detailInfo.update_at ? detailInfo.update_at : '-'
          }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('device.status')">{{ detailInfo.online_title }}</el-descriptions-item>
        <el-descriptions-item label="IMSI">{{ detailInfo.imsi }}</el-descriptions-item>
        <el-descriptions-item :label="$t('detail.signal')">{{
            detailInfo.signal ? detailInfo.signal : '-'
          }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('detail.volt')">{{
            detailInfo.power ? detailInfo.power : '-'
          }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('detail.step')">{{
            detailInfo.step_number ? detailInfo.step_number : '-'
          }}
        </el-descriptions-item>
      </el-descriptions>
      <el-divider/>
      <el-descriptions :title="$t('detail.warning')" column="2" border>
        <el-descriptions-item :label="$t('detail.last_warning')">
          {{ detailInfo.alarm_content ? detailInfo.alarm_content : '-' }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('detail.last_alarm_time')">{{
            detailInfo.alarm_time ? detailInfo.alarm_time : '-'
          }}
        </el-descriptions-item>
      </el-descriptions>
      <el-divider/>
      <el-descriptions :title="$t('detail.temp')" :column="2" border>
        <el-descriptions-item :label="$t('detail.measurements')">{{
            detailInfo.temp ? detailInfo.temp : '-'
          }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('detail.measure_time')">{{
            detailInfo.temp_time ? detailInfo.temp_time : '-'
          }}
        </el-descriptions-item>
      </el-descriptions>
      <el-divider/>
      <el-descriptions :title="$t('detail.hrv')" :column="2" border>
        <el-descriptions-item :label="$t('detail.measurements')">{{
            detailInfo.pressure ? detailInfo.pressure : '-'
          }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('detail.measure_time')">{{
            detailInfo.pressure_time ? detailInfo.pressure_time : '-'
          }}
        </el-descriptions-item>
      </el-descriptions>
      <el-divider/>
      <el-descriptions :title="$t('detail.heart')" column="2" border>
        <el-descriptions-item :label="$t('detail.measurements')">{{
            detailInfo.heart_rate ? detailInfo.heart_rate : '-'
          }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('detail.measure_time')">{{
            detailInfo.heart_time ? detailInfo.heart_time : '-'
          }}
        </el-descriptions-item>
      </el-descriptions>
      <el-divider/>
      <el-descriptions :title="$t('detail.blood')" border>
        <el-descriptions-item :label="$t('detail.diastolic')">{{
            detailInfo.blood_diastolic ? detailInfo.blood_diastolic : '-'
          }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('detail.systolic')">{{
            detailInfo.blood_systolic ? detailInfo.blood_systolic : '-'
          }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('detail.measure_time')">{{
            detailInfo.blood_time ? detailInfo.blood_time : '-'
          }}
        </el-descriptions-item>
      </el-descriptions>
      <el-divider/>
      <el-descriptions :title="$t('detail.oxygen')" column="2" border>
        <el-descriptions-item :label="$t('detail.measurements')">{{
            detailInfo.oxygen ? detailInfo.oxygen : '-'
          }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('detail.measure_time')">{{
            detailInfo.oxygen_time ? detailInfo.oxygen_time : '-'
          }}
        </el-descriptions-item>
      </el-descriptions>
      <el-divider/>
      <el-descriptions :title="$t('detail.location')" border>
        <el-descriptions-item :label="$t('detail.lon')">{{
            detailInfo.longitude ? detailInfo.longitude : '-'
          }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('detail.lat')">{{
            detailInfo.latitude ? detailInfo.latitude : '-'
          }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('detail.location')">{{
            detailInfo.location ? detailInfo.location : '-'
          }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('device.position_time')">{{
            detailInfo.location_time ? detailInfo.location_time : '-'
          }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('detail.position_method')">{{
            detailInfo.location_type ? detailInfo.location_type : '-'
          }}
        </el-descriptions-item>
      </el-descriptions>
      <el-divider/>
      <el-descriptions :title="$t('detail.exercise')" border>
        <el-descriptions-item :label="$t('detail.calorie')">{{
            detailInfo.calorie ? detailInfo.calorie : '-'
          }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('detail.move_distance')">{{
            detailInfo.distance ? detailInfo.distance : '-'
          }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('detail.average_step')">{{
            detailInfo.avg_step ? detailInfo.avg_step : '-'
          }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('detail.jump_times')">{{
            detailInfo.bounce_times ? detailInfo.bounce_times : '-'
          }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('detail.jump_height')">{{
            detailInfo.bounce_height ? detailInfo.bounce_height : '-'
          }}
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
    <el-divider/>
    <el-card class="box-card">
      <template v-slot:header>
        <div>
          <span>{{ $t('detail.alarm_list') }}</span>
        </div>
      </template>
      <el-table
          ref="multipleTableRef"
          :data="alarmData"
          style="width: 100%;"
          border
      >
        <!--        <el-table-column fixed property="id" label="ID" width="60"/>-->
        <el-table-column fixed property="name" :label="$t('index.device_name')"/>
        <el-table-column property="imei" label="设备IMEI" width="150"/>
        <el-table-column property="device_type" :label="$t('index.device_type')"/>
        <el-table-column property="type_tip" :label="$t('index.alarm_type')"/>
        <el-table-column property="content" :label="$t('index.alarm_content')"/>
        <el-table-column property="online" :label="$t('device.device_status')"/>
        <el-table-column property="create_at" :label="$t('index.alarm_time')" width="160"/>
        <el-table-column width="80" :label="$t('detail.operating')">
          <template #default="scope">
            <el-button size="small" @click="handleLocation(scope.$index, scope.row)">
              {{ $t('detail.detail') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          class="el-pagination"
          v-model:currentPage="alarmForm.page"
          v-model:page-size="alarmForm.size"
          :page-sizes="[10, 20, 30, 50]"
          :small="false"
          :background="false"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
      />
    </el-card>
    <el-drawer v-model="alarmDialog" width="60%">
      <template v-slot:header>
        {{ $t('detail.alarm_location') }}
      </template>
      <template v-slot:default>
        <div class="mapCont" id="map">
        </div>
        <div class="deviceMes">
          <p> {{ $t('device.name') }} : {{ deviceMes.name }}</p>
          <p> 设备IMEI : {{ deviceMes.imei }}</p>
          <p> {{ $t('index.alarm_type') }} : {{ deviceMes.type_tip }}</p>
          <p> {{ $t('index.alarm_time') }} : {{ deviceMes.create_at }}</p>
          <p> {{ $t('detail.alarm_location') }} : {{ deviceMes.location }}</p>
        </div>
      </template>
      <template v-slot:footer>
        <div style="flex: auto">
          <el-button type="primary" @click="alarmDialog = false">{{ $t('device.close') }}</el-button>
        </div>
      </template>
    </el-drawer>
  </div>
</template>

<script>
import device from '@/model/device'
import alarm from '@/model/alarm'
import tool from '@/utils/tool'
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
  name: 'device-detail',
  data() {
    return {
      imei: this.$route.query.imei,
      alarmForm: {
        page: 1,
        size: 10
      },
      detailInfo: {},
      alarmData: [],
      totalPage: 0,
      deviceMes: {},
      alarmDialog: false,
      center: [],
      title: ''
    }
  },
  async mounted() {
    await this.deviceInfo()
    await this.deviceAlarm()
  },
  methods: {
    async deviceInfo() {
      const ret = await device.detail({
        imei: this.imei
      })
      if (ret.code === 200) {
        this.detailInfo = ret.data
      } else {
        tool.notice(ret.message)
      }
    },
    async deviceAlarm() {
      const ret = await alarm.list(Object.assign(this.alarmForm, {imei: this.imei}))
      if (ret.code === 200) {
        this.alarmData = ret.data.list
        this.totalPage = ret.data.count
      } else {
        tool.notice(ret.message)
      }
    },
    handleSizeChange(val) {
      this.alarmForm.size = val
      this.deviceAlarm()
    },
    handleCurrentChange(val) {
      this.alarmForm.page = val
      this.deviceAlarm()
    },
    async handleLocation(index, row) {
      this.alarmDialog = true
      this.deviceMes = row
      const AMap = await AMapLoader.load({
        key: this.$store.state.amap_key, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: this.$store.state.amap_version, // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [] //插件列表
      })
      let map = new AMap.Map("map", {
        center: [this.deviceMes.longitude, this.deviceMes.latitude],
        zoom: 12
      });
      let trafficLayer = new AMap.TileLayer.Traffic({
        zIndex: 2
      });
      let marker = new AMap.Marker({
        icon: this.$store.state.alarm,
        position: [this.deviceMes.longitude, this.deviceMes.latitude],
        title: this.deviceMes.location
      });
      // 将以上覆盖物添加到地图上
      // 单独将点标记添加到地图上
      map.add(marker);
      map.add(trafficLayer); //添加图层到地图
    }
  }
}
</script>
<style scoped>
.el-pagination {
  margin: 20px 0;
  float: right;
}

.mapCont {
  width: 100%;
  height: 350px;
}

.deviceMes {
  margin-top: 20px;
}

.deviceMes p {
  line-height: 30px;
  color: #666;
}

.deviceMes p span {
  display: inline-block;
  width: 80px;
  margin-right: 20px;
  text-align: right;
  color: #333;
}
</style>
