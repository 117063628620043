import request from '../utils/request'

export default {
  async list (data) {   // 获取告警列表
    const ret = await request('/v1/alarm/list', data,'POST')
    return ret
  },
  async fence (data) {    // 获取围栏告警列表
    const ret = await request('/v1/alarm/fence', data,'POST')
    return ret
  },
  async setting (data) {  // 设置用户告警信息
    const ret = await request('/v1/alarm/setting', data,'POST')
    return ret
  },
  async get_setting (data) {  // 获取用户告警信息
    const ret = await request('/v1/alarm/get_setting', data,'POST')
    return ret
  },
  async user (data) {   // 获取用户告警设置信息
    const ret = await request('/v1/alarm/user', data,'POST')
    return ret
  },
  async operSet (data) {   // 保存用户整体告警设置信息
    const ret = await request('/v1/alarm/operate_system_alarm_setting', data,'POST')
    return ret
  },
  async alarmSet () {   // 获取用户整体告警设置信息
    const ret = await request('/v1/alarm/system_alarm_setting', '','POST')
    return ret
  },
  async read (data) {   // 获取告警列表
    return await request('/v1/alarm/read', data, 'POST')
  }
}
